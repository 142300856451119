.app__about-container{
  padding-top: 4rem;
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
   
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 18rem;
  
  display: flex;
  
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    text-align: center;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
  }

  @media screen and (min-width:2000px) {
    width: 370px;
    margin: 2rem 4rem;
  }
}
