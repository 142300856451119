#home {
  position: relative;
  background: url(../../assets/bgIMG.png);
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  overflow: hidden;

  .app__wrapper {
    padding: 0;

   
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 8rem 2rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (mix-width: 450px) {
    padding: 8rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    margin-top: 1rem;
    margin-left: 2rem;
    padding: 1rem 2rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    margin: 1rem 0 1rem 0;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }
  span {
    font-size: 1.5rem;

    @media screen and (mix-width: 2000px) {
      font-size: 5rem;
    }
  }
  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circle {
  
  display: flex;
  flex: 0.50;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background: var(--white-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }
  div:nth-child(1) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }
  div:nth-child(2) {
    width: 100px;
    height: 100px;
  }
  div:nth-child(3) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }
  div:nth-child(4) {
    width: 70px;
    height: 70px;
  }

  @media screen and(min-width:2000px) {
    div:nth-child(1) {
      margin: 1.75rem;
      width: 400px;
      height: 400px;
    }
    div:nth-child(2) {
      width: 175px;
      height: 175px;
    }
    div:nth-child(3) {
      margin: 1.75rem;
      width: 175px;
      height: 175px;
    }
    div:nth-child(4) {
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (min-width:485px){
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    
    div{
      margin: 1rem;
      
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
    
  }

  #profile{
   
  -webkit-mask-image: linear-gradient(black 90% , transparent );
  mask-image: linear-gradient(  black 90% , transparent  );

    width: 40rem;
    height: 40rem;

    
    
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:2;
    z-index: 0;
    width: 30rem;
    height: 30rem;
    

  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;


  }

  @media screen and (max-width: 750px) {
  #profile{
    width: 20rem;
    height: 20rem;
    
  } 
   .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:2;
    z-index: 0;
    width: 15rem;
    height: 15rem;
    

  }

}

    @media screen and (max-width: 340px) {
  #profile{
    width: 15rem;
    height: 15rem;

  }

   .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:2;
    z-index: 0;
    width: 10rem;
    height: 10rem;
    

  }

}
}