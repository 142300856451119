@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --dark-black-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
   --clr-primary: rgb(54,112,199);
  --clr-light: #f4f4f4;
  --clr-dark: #333;
  --clr-warning: rgb(99,36,36);
  --blue:    #0d6efd;
   --indigo:  #6610f2;
   --purple:  #6f42c1;
   --pink:    #d63384;
   --red:     #dc3545;
   --orange:  #fd7e14;
   --yellow:  #ffc107;
   --green:   #198754;
   --teal:    #20c997;
   --cyan:    #0dcaf0;
   --white:    #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black:    #000;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}